<template>
  <div class="toast-container">
    <toast
      v-for="toast in $store.state.notifications.toastNotifications"
      :key="toast.id"
      v-bind="toast"
    />
  </div>
</template>

<script>
import Toast from './toast.vue';

export default {
  components: {
    Toast,
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  position: fixed;
  top: 80px;
  right: 15px;
  z-index: var(--z-toast);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 500px;
  height: 0;
  overflow: visible;

  @media screen and (max-width: 470px) {
    right: 0;
  }
}

.with-impersonation {
  .toast-container {
    top: 120px;
  }
}
</style>
