import { axios, toCamelCase, toSnakeCase } from '@utils';

async function submitSurvey(form) {
  const response = await axios.post('/survey_answers', toSnakeCase(form));
  return toCamelCase(response.data);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  submitSurvey,
};
