import cloneDeep from 'lodash/cloneDeep';
import {
  fetchMatchesRequest,
  updateMatchesRequest,
  createMatchesRequest,
} from './matches-request.service';

export default {
  namespaced: true,
  state: {
    id: null,
    startDates: {},
    startDatesNotes: null,
    hasSameStartDates: null,
    scheduleNeeds: {},
    scheduleNeedsNotes: null,
    hasSameScheduleNeeds: null,
    centerSearchLocationType: null,
    centerSearchLocationAddresses: {},
    centerSearchLocationNotes: null,
    budget: {
      amount: null,
      timeframe: 'month',
    },
    careProviderType: null,
    careProviderPhilosophies: [],
    careProviderPreferences: null,
    currentlyEnrolledFacilityName: null,
    hasJoinedWaitlists: null,
    waitlistNames: null,
    excludedFacilitiesNotes: null,
    priorities: null,
    dependents: null,
    requestStatus: 'in_progress',
  },
  mutations: {
    setState: (state, data) => {
      Object.keys(data).forEach((key) => {
        state[key] = data[key];
      });
    },
  },
  getters: {
    hasMultipleDependents(state) {
      return state.dependents?.length > 1;
    },
    initialStateForStartDates(state) {
      const initialState = {};
      if (state.dependents) {
        state.dependents.forEach((id) => {
          initialState[id] = null;
        });
      }
      return initialState;
    },
    initialStateForScheduleNeeds(state) {
      const initialState = {};

      if (state.dependents) {
        state.dependents.forEach((id) => {
          initialState[id] = {
            monday: { from: null, to: null, checked: false },
            tuesday: { from: null, to: null, checked: false },
            wednesday: { from: null, to: null, checked: false },
            thursday: { from: null, to: null, checked: false },
            friday: { from: null, to: null, checked: false },
          };
        });
      }
      return initialState;
    },
  },
  actions: {
    async fetchMatchesRequest({ commit, dispatch }) {
      try {
        const response = await fetchMatchesRequest();
        await commit('setState', response);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not load matches request',
          error,
        }, { root: true });
      }
    },
    async saveMatchesRequest({ dispatch, commit }, data) {
      try {
        const newData = cloneDeep(data);
        await commit('setState', newData);
        if (newData.id) {
          const response = await updateMatchesRequest(newData);
          await commit('setState', response);
        } else {
          const response = await createMatchesRequest(newData);
          await commit('setState', response);
        }
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not save matches request',
          error,
        }, { root: true });
      }
    },
  },
};
