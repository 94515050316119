import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import parentsStore from '@parent/stores/parent.store';
import {
  setupGuards, confirmAccountGuard, onboardingGuard, impersonatedGuard,
} from './commonRouteGuards';

Vue.use(VueRouter);

const beforeConfirmAccount = confirmAccountGuard(parentsStore);
const beforeOnboarding = onboardingGuard(parentsStore);
const beforeImpersonated = impersonatedGuard(parentsStore);

function loadInitialStateForSearchTabs() {
  parentsStore.dispatch('facilities/loadOpinionCounts');
  parentsStore.dispatch('facilities/loadFacilitiesByOpinion', 'favorited');
  parentsStore.dispatch('facilities/loadFacilitiesByOpinion', 'saved');
  parentsStore.dispatch('facilities/loadFacilitiesByOpinion', 'ignored');
  parentsStore.dispatch('facilities/fetchMatchesFacilitiesForParent');
}

const router = new VueRouter({
  mode: 'history',

  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },

  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: (to, from, next) => {
        parentsStore.dispatch('isInitialized', undefined, { root: true });
        next({ name: 'search' });
      },
    },
    {
      name: 'onboarding',
      path: '/onboarding',
      component: () => import('../../parent/components/onboarding/onboarding.vue'),
      beforeEnter: beforeOnboarding,
    },
    {
      name: 'offers',
      path: '/offers/:seatId',
      component: () => import('../../parent/components/seat-offers/landing-page.vue'),
    },
    {
      name: 'secret-onboarding',
      path: '/secret-parent-onboarding',
      component: () => import('../../parent/components/onboarding/onboarding.vue'),
      beforeEnter: beforeImpersonated,
    },
    {
      name: 'confirm-account',
      path: '/confirm-account',
      component: () => import('@components/confirmAccountPage.vue'),
      beforeEnter: beforeConfirmAccount,
    },
    {
      path: '/childcare',
      name: 'childcare',
      redirect: ({ params, query }) => ({ name: 'search', query: { ...params, ...query } }),
      component: { template: '<router-view></router-view>' },
      children: [
        {
          name: 'search',
          path: 'search',
          alias: 'search-form',
          component: () => import('../../parent/components/find-care/find-care.vue'),
          beforeEnter: (to, from, next) => {
            loadInitialStateForSearchTabs();
            parentsStore.commit(
              'facilities/setSearchSortOption',
              parentsStore.getters['facilities/currentSearchSortKey'](),
            );
            next();
          },
        },
        {
          name: 'matches',
          path: 'matches',
          component: () => import('../../parent/components/find-care/facilities-list-and-map.vue'),
          beforeEnter: (to, from, next) => {
            loadInitialStateForSearchTabs();
            parentsStore.commit(
              'facilities/setMatchesSortOption',
              parentsStore.getters['facilities/currentMatchesSortKey'](),
            );
            next();
          },
        },
        {
          name: 'shortlist',
          path: 'shortlist',
          component: () => import('../../parent/components/find-care/facilities-list-and-map.vue'),
          beforeEnter: (to, from, next) => {
            loadInitialStateForSearchTabs();
            parentsStore.commit(
              'facilities/setShortlistSortOption',
              parentsStore.getters['facilities/currentShortlistSortKey'](),
            );
            next();
          },
        },
        {
          name: 'favorited',
          path: 'favorited',
          redirect: 'shortlist',
        },
        {
          name: 'saved',
          path: 'saved',
          redirect: 'shortlist',
        },
        {
          name: 'ignored',
          path: 'ignored',
          component: () => import('../../parent/components/find-care/facilities-list-and-map.vue'),
          beforeEnter: (to, from, next) => {
            loadInitialStateForSearchTabs();
            next();
          },
        },
        {
          name: 'facility-profile',
          path: ':state/:city/:facilityFriendlyId',
        },
        {
          name: 'facility',
          path: ':state/:city/:facilityFriendlyId',
          beforeEnter: (to) => {
            window.location.href = to.fullPath;
          },
        },
        {
          name: 'Childcare / Search',
          path: ':state/:city',
          // handle depreciated route
          redirect: ({ params, query }) => ({ name: 'search', query: { ...params, ...query } }),
        },
      ],
    },
    {
      path: '/tours',
      redirect: '/my-programs',
    },
    {
      path: '/my-programs',
      name: 'my-programs',
      component: () => import('@parent/components/my-programs'),
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@parent/components/settings'),
    },
    {
      path: '/childcare/:state/:city/:facilityFriendlyId/pay',
      name: 'send-payment',
      component: () => import('@parent/components/payments/send-payment/pay-me-flow'),
    },
    {
      path: '/childcare/:state/:city/:facilityFriendlyId/payment-request',
      name: 'request-payment',
      component: () => import('@parent/components/payments/request-payment/payment-request-summary'),
    },
    {
      path: '/childcare/:state/:city/:facilityFriendlyId/accept-autopay',
      name: 'accept-autopay',
      component: () => import('@parent/components/payments/accept-autopay'),
    },
    {
      path: '/childcare/:state/:city/:facilityFriendlyId/open-house-invite',
      name: 'open-house-invite',
      component: () => import('@parent/components/schedule-tour/open-house-invite'),
    },
    {
      path: '/payments',
      redirect: !parentsStore.state.user.current.parentPaymentsEnabled && { name: 'childcare' },
      component: () => import('@parent/components/payments/payments'),
      children: [
        {
          name: 'payments',
          path: '/',
          component: () => import('@parent/components/payments/summary'),
        },
        {
          name: 'invoice',
          path: 'invoice/:id',
          component: () => import('@parent/components/payments/invoice'),
          props: (route) => ({ ...route.params, id: Number(route.params.id) }),
        },
      ],
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@parent/components/activity_feed'),
      props: { feed: 'account' },
    },
    {
      path: '/messaging',
      name: 'messaging',
      component: () => import('@parent/components/messaging'),
    },
    {
      path: '/surveys',
      name: 'survey',
      component: () => import('@parent/components/surveys/form'),
    },
    {
      path: '/childcare/:state/:city/:facilityFriendlyId/tour',
      name: 'tour-action',
      component: () => import('@parent/components/tour-actions'),
    },
    { path: '*', redirect: { name: 'childcare' } },
  ],
});

setupGuards(parentsStore, router);

export default router;
