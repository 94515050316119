import { axios, toCamelCase, toSnakeCase } from '@utils';
import userService from '@services/user.service';

const { removeAvatar, updateNotificationSettings, notificationSettings } = userService;

async function updateSettings({ phoneNumber, contactPreferenceSms }) {
  const response = await axios.patch('/api/v1/parent_users/update_settings', {
    phone_number: phoneNumber,
    contact_preference_sms: contactPreferenceSms,
  });
  return toCamelCase(response.data);
}

async function updateName({ firstName, lastName }) {
  const response = await axios.patch('/api/v1/parent_users/update_name', {
    first_name: firstName,
    last_name: lastName,
  });
  return toCamelCase(response.data);
}

async function updateNameEmail({
  email, firstName, lastName, id,
}) {
  const response = await axios.patch('/api/v1/parent_users/update_name_email', {
    email,
    first_name: firstName,
    last_name: lastName,
    id,
  });
  return toCamelCase(response.data);
}

function mapOldToNewOnboardingUser(oldUser) {
  const {
    reasonForNoStartDate, needsSchoolAgedCare, currentProviderName, // to remove
    wantsConciergeAssistance, isPlanningToHaveChildren, // to remove
    isLooking,
    wantsToPayAProvider,
    seekingDiscountOnExistingEnrollment,
    startDateIsAnEstimate,
    wantsToSwitchProviders,
    ...rest
  } = toCamelCase(oldUser);
  return {
    joinedForChildCareSearch: isLooking,
    joinedForFsaDollars: wantsToPayAProvider,
    joinedForCurrentProviderOffersCheck: seekingDiscountOnExistingEnrollment,
    startDateIsFlexible: startDateIsAnEstimate,
    ...rest,
  };
}

async function getUser() {
  const user = await userService.getUser();
  return mapOldToNewOnboardingUser(user);
}

async function updateOnboardingForParent(userObj) {
  const { naviaParent, perentPaymentsEnabled, ...rest } = userObj;
  const user = await userService.updateUser(rest);
  return mapOldToNewOnboardingUser(user);
}

async function sendEnrollment(enrollmentData) {
  const { data } = await axios.post(
    '/enrollments.json',
    { enrollment: toSnakeCase(enrollmentData) },
  );
  return toCamelCase(data);
}

async function fetchParentEnrollments() {
  const { data } = await axios.get(
    '/enrollments.json',
  );
  return toCamelCase(data);
}

function requestMoreInformation({ facilityId, message }) {
  return axios.put(`/child_care_facilities/${facilityId}/request_info.json`, { message });
}

async function fetchInfoRequestHistory(facilityId) {
  const { data } = await axios.get(`/child_care_facilities/${facilityId}/latest_info_requested_at.json`);
  return data;
}

async function fetchSubsidies() {
  try {
    const { data } = await axios.get('/api/v1/parent_users/fetch_subsidies');
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function updateSubsidies(params) {
  try {
    const { data } = await axios.patch('/api/v1/parent_users/update_subsidies', params);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function addUser(userData) {
  try {
    const { data } = await axios.post('/users', { user: toSnakeCase(userData) });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

export {
  getUser,
  updateOnboardingForParent,
  updateNotificationSettings,
  updateSettings,
  updateName,
  notificationSettings,
  removeAvatar,
  sendEnrollment,
  fetchParentEnrollments,
  requestMoreInformation,
  fetchInfoRequestHistory,
  fetchSubsidies,
  updateSubsidies,
  addUser,
  updateNameEmail,
};
