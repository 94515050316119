import { axios, toCamelCase } from '../../utils';

const BASE_PATH = 'recurring_payment_configs';

async function fetchRecurringPaymentConfigs() {
  const response = await axios.get(`/${BASE_PATH}.json`);
  return toCamelCase(response.data);
}

async function fetchRecurringPaymentConfig(paymentId) {
  const response = await axios.get(`/${BASE_PATH}/${paymentId}.json`);
  return toCamelCase(response.data);
}

async function updateRecurringPaymentsConfig(paymentId, data) {
  const response = await axios.patch(`/${BASE_PATH}/${paymentId}.json`, data);
  return toCamelCase(response.data);
}

async function cancelRecurringPaymentsConfig(paymentId) {
  const response = await axios.put(`/${BASE_PATH}/${paymentId}/cancel.json`);
  return toCamelCase(response.data);
}

/* eslint-disable import/prefer-default-export */
export {
  fetchRecurringPaymentConfigs,
  fetchRecurringPaymentConfig,
  updateRecurringPaymentsConfig,
  cancelRecurringPaymentsConfig,
};
