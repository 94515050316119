import {
  fetchOpenHouse,
  acceptOpenHouse,
  declineOpenHouse,
} from './openHouse.service';

export default {
  namespaced: true,

  state: {
    openHouse: {},
  },

  getters: {
    openHouse(state) {
      return state.openHouse;
    },
  },

  mutations: {
    setOpenHouse: (state, openHouse) => {
      state.openHouse = openHouse;
    },
    incrementOpenHouseCount: (state) => {
      const tourCount = state.openHouse.tourSchedule.tourCount + 1;
      state.openHouse = {
        ...state.openHouse,
        tourSchedule: {
          ...state.openHouse.tourSchedule,
          tourCount,
        },
      };
    },
  },

  actions: {
    async fetchOpenHouse({ commit, dispatch }, { inviteId }) {
      try {
        const openHouse = await fetchOpenHouse(inviteId);
        commit('setOpenHouse', openHouse);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load open house', error }, { root: true });
      }
    },

    async acceptOpenHouse({ dispatch }, { inviteId }) {
      try {
        await acceptOpenHouse(inviteId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not accept open house', error }, { root: true });
      }
    },

    async declineOpenHouse({ dispatch }, { inviteId }) {
      try {
        await declineOpenHouse(inviteId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not decline open house', error }, { root: true });
      }
    },
  },
};
