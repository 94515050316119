import { shouldUpdateBounds, getMarkerRadiusInDegrees } from '@utils';
import debounce from 'lodash/debounce';

const geoSearchTypes = {
  bounds: 'viewport_bounds',
  center: 'center_plus_radius',
};

export default {
  namespaced: true,

  state: {
    records: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    favorited: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    saved: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    ignored: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    shortlist: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    matches: {
      bounds: {},
      zoom: 13,
      showMapOnMobile: false,
    },
    mapViewport: {
      bounds: {
        neLat: null,
        neLon: null,
        swLat: null,
        swLon: null,
      },
      dimensions: {
        width: 0,
        height: 0,
      },
    },
    geoSearchType: geoSearchTypes.center,
  },

  getters: {
    hasBounds: (state) => state.mapViewport.bounds && !!state.mapViewport.bounds.neLat,

    geoSearchTypes() {
      return geoSearchTypes;
    },

    searchWhenMapMove(state) {
      return state.geoSearchType === geoSearchTypes.bounds;
    },

    markerRadiusInDegrees(state) {
      if (!state.mapViewport.bounds && !state.mapViewport.dimensions) {
        return 0;
      }
      const dimInDegrees = state.mapViewport.bounds.neLat - state.mapViewport.bounds.swLat;
      return getMarkerRadiusInDegrees(state.mapViewport.dimensions.width, dimInDegrees);
    },
  },

  mutations: {
    setRecordsZoom: (state, payload) => {
      state.records.zoom = payload;
    },

    setFavoritedZoom: (state, payload) => {
      state.favorited.zoom = payload;
    },

    setIgnoredZoom: (state, payload) => {
      state.ignored.zoom = payload;
    },

    setSavedZoom: (state, payload) => {
      state.saved.zoom = payload;
    },

    setShortlistZoom: (state, payload) => {
      state.shortlist.zoom = payload;
    },

    setMatchesZoom: (state, payload) => {
      state.matches.zoom = payload;
    },

    setRecordsBounds: (state, payload) => {
      state.records.bounds = payload;
    },

    setFavoritedBounds: (state, payload) => {
      state.favorited.bounds = payload;
    },

    setSavedBounds: (state, payload) => {
      state.saved.bounds = payload;
    },

    setIgnoredBounds: (state, payload) => {
      state.ignored.bounds = payload;
    },

    setShortlistBounds: (state, payload) => {
      state.shortlist.bounds = payload;
    },

    setMatchesBounds: (state, payload) => {
      state.matches.bounds = payload;
    },

    setRecordsShowMapOnMobile: (state, payload) => {
      state.records.showMapOnMobile = payload;
    },

    setFavoritedShowMapOnMobile: (state, payload) => {
      state.favorited.showMapOnMobile = payload;
    },

    setIgnoredShowMapOnMobile: (state, payload) => {
      state.ignored.showMapOnMobile = payload;
    },

    setSavedShowMapOnMobile: (state, payload) => {
      state.saved.showMapOnMobile = payload;
    },

    updateMapViewport: (state, { bounds, dimensions }) => {
      state.mapViewport = {
        dimensions: dimensions || state.mapViewport.dimensions,
        bounds,
      };
    },

    setViewportBounds: (state, payload) => {
      state.mapViewport.bounds = payload;
    },

    setGeoSearchType: (state, type) => {
      state.geoSearchType = type;
    },
  },

  actions: {
    setMapAttribute({ commit, rootGetters }, { payload, something }) {
      if (rootGetters.showFavorited) {
        commit(`setFavorited${something}`, payload);
      } else if (rootGetters.showSaved) {
        commit(`setSaved${something}`, payload);
      } else if (rootGetters.showIgnored) {
        commit(`setIgnored${something}`, payload);
      } else if (rootGetters.showShortlist) {
        commit(`setShortlist${something}`, payload);
      } else {
        commit(`setRecords${something}`, payload);
      }
    },

    setZoom({ dispatch }, payload) {
      dispatch('setMapAttribute', { payload, something: 'Zoom' });
    },

    setBounds({ dispatch }, payload) {
      dispatch('setMapAttribute', { payload, something: 'Bounds' });
    },

    setCenter({ dispatch }, payload) {
      dispatch('setMapAttribute', { payload, something: 'Center' });
    },

    setShowMapOnMobile({ dispatch }, payload) {
      dispatch('setMapAttribute', { payload, something: 'ShowMapOnMobile' });
    },

    setBoundsFromURLSync({ commit }, payload) {
      commit('setRecordsBounds', payload);
      commit('setViewportBounds', payload);
    },

    async updateMapViewport({
      commit, getters, dispatch, state, rootGetters,
    }, { bounds, dimensions }) {
      const oldBounds = state.records.bounds;
      const oldDimensions = state.mapViewport.dimensions;
      const areOldDimensionsDefined = oldDimensions.height !== undefined;

      await commit('updateMapViewport', {
        bounds,
        dimensions,
      });

      if (getters.searchWhenMapMove && rootGetters.showRecords && areOldDimensionsDefined) {
        dispatch('debouncedRunBoundsSearch', { bounds, oldBounds, oldDimensions });
      }
    },

    debouncedRunBoundsSearch: debounce(({ dispatch }, params) => {
      dispatch('runBoundsSearch', params);
    }, 200),

    async runBoundsSearch({
      commit, dispatch, state,
    }, { bounds, oldBounds, oldDimensions }) {
      const shouldUpdate = shouldUpdateBounds({
        oldBounds,
        newBounds: bounds,
        oldDimensions,
        newDimensions: state.mapViewport.dimensions,
      });

      commit('setRecordsBounds', bounds);

      if (shouldUpdate) {
        dispatch('search/runSearch', undefined, { root: true });
      }
    },
  },
};
