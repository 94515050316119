import { axios, toCamelCase } from '@utils';

async function fetchOpenHouse(inviteId) {
  const response = await axios.get(`/api/v1/legup/open_house_invites/${inviteId}`);
  return toCamelCase(response.data);
}

async function acceptOpenHouse(inviteId) {
  const response = await axios.post(`/api/v1/legup/open_house_invites/${inviteId}/accept`);
  return toCamelCase(response.data);
}

async function declineOpenHouse(inviteId) {
  const response = await axios.post(`/api/v1/legup/open_house_invites/${inviteId}/decline`);
  return toCamelCase(response.data);
}

export {
  fetchOpenHouse,
  acceptOpenHouse,
  declineOpenHouse,
};
