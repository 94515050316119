<template>
  <div class="k-textarea" :class="{ 'k-textarea--autogrow': autogrow }">
    <textarea
      ref="inputField"
      class="k-textarea__input"
      :class="{
        'k-textarea__input--bright': bright,
        'k-textarea__input--disabled': disabled
      }"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :disabled="disabled"
      v-on="inputListeners"
    ></textarea>
    <div class="k-textarea__spacer" v-if="autogrow">{{ `${value}\n` }}</div>
  </div>
</template>

<script>

function getValue(value, maxLength) {
  if (maxLength && value) {
    return value.substring(0, maxLength);
  }
  return value;
}
export default {
  name: 'k-textarea',
  props: {
    autogrow: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    bright: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        change(event) {
          vm.$emit('change', getValue(event.target.value, vm.maxLength));
        },
        input(event) {
          vm.$emit('input', getValue(event.target.value, vm.maxLength));
        },
        blur(event) {
          vm.isFocused = false;
          vm.$emit('blur', getValue(event.target.value, vm.maxLength));
        },
        focus(event) {
          vm.isFocused = true;
          vm.$emit('focus', getValue(event.target.value, vm.maxLength));
        },
      };
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.$refs.inputField.focus();
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .k-textarea {
    width: 100%;
    max-width: 70ch;

    &__input,
    &__spacer {
      display: block;
      width: 100%;
      padding: var(--grid-unit) calc(var(--grid-unit) * 1.5);
      margin: 0;

      /* Turn off scroll bars in IE unless needed */
      overflow: auto;
      font-family: var(--sans-serif);
      line-height: 1.3;
      border: 1px solid transparent;
      border-radius: var(--radius);
    }

    &__input {
      color: var(--gray-80);
      background: var(--gray-0);
      border-color: var(--gray-80);
      outline: none;

      &--bright {
        background: var(--gray-0);
        border-color: var(--gray-0);
      }

      &--disabled {
        color: var(--gray-20);
        border-color: var(--gray-20);
      }

      &:focus {
        box-shadow: 0 0 0 2px var(--input-focus-color);
      }
    }

    &__spacer {
      white-space: pre-line;
    }
  }

  .k-textarea--autogrow {
    position: relative;
    min-height: calc(var(--grid-unit) * 5);

    .k-textarea {
      &__input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        max-height: 320px;
        resize: none;
      }

      &__spacer {
        max-height: 320px;
      }
    }
  }
</style>
