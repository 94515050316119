<template>
  <label
    class="k-radio"
    :class="classes"
  >
    <input
      type="radio"
      class="k-radio__input"
      :class="{'k-radio__input--checked' : fakeChecked}"
      v-bind="$attrs"
      :id="radioId"
      :value="value"
      :checked="shouldBeChecked"
      @change="updateInput"
    />
    <span class="k-radio__state"></span>
    <span class="k-radio__label" :for="radioId">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'k-radio',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    id: {
      type: String,
    },
    value: {
      default: null,
    },
    modelValue: {
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    fakeChecked: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value;
    },

    radioId() {
      // TODO: better id gen for elements.
      // this is a unique-ish ID assuming we have less a few hundred
      // implicitly id'd radio buttons on the page.
      return this.id || `radio-${Math.random(1000)}${Date.now()}`;
    },

    classes() {
      return {
        'k-radio--checked': this.shouldBeChecked,
        'k-radio--disabled': !!this.$attrs.disabled,
        'k-radio--stacked': this.stacked,
        'k-radio--inline': this.inline,
        'k-radio--button': this.button,
      };
    },
  },

  methods: {
    updateInput() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .k-radio {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &__label {
      display: inline-flex;
      flex-direction: column;
      margin: 0;
      margin-left: 8px;
      color: var(--gray-80);
      vertical-align: middle;
      user-select: none;
      font-weight: 400;
    }

    &__state {
      display: inline-block;
      flex-shrink: 0;
      width: 19px;
      height: 19px;
      vertical-align: middle;
      background: var(--gray-0);
      border: 2px solid var(--gray-80);
      border-radius: 50%;
      transition: border-width 150ms ease-in;
      cursor: pointer;
    }

    &--inline {
      display: inline-flex;
    }
  }

  .k-radio--disabled {
    cursor: not-allowed;

    .k-radio {
      &__state { border-color: var(--gray-30); }
      &__label { color: var(--gray-50); }
    }
  }

  .k-radio--checked .k-radio__state,
  .k-radio__input--checked + .k-radio__state,
  .k-radio__input:checked + .k-radio__state {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-width: 6px;
  }

  .k-radio__input:focus + .k-radio__state {
    box-shadow: 0 0 0 2px var(--input-focus-color);
  }

  .k-radio--stacked {
    .k-radio__label {
      display: flex;
      flex-direction: column;
    }
  }

  .k-radio--button {
    + .k-radio--inline.k-radio--button {
      margin: 5px;
    }

    .k-radio {
      &__state { /* stylelint-disable-line */
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        border: 1px solid var(--gray-50);
        border-radius: 50px;

        &::after {
          display: none;
        }
      }

      &__label {
        position: relative;
        padding: 10px 20px;
        margin-left: 0;
        color: var(--gray-80);
      }

      &__input--checked ~ .k-radio__label,
      &__input:checked ~ .k-radio__label {
        color: var(--gray-0);
      }

      &__input--checked ~ .k-radio__state,
      &__input:checked ~ .k-radio__state {
        background-color: var(--viridian-50);
        border-color: var(--viridian-50);
        border-width: 1px;
      }
    }
  }
</style>
