import {
  submitSurvey,
} from './surveys.service';

export default {
  namespaced: true,

  state: {
    surveySubmitted: false,
  },

  actions: {
    async submitSurvey({ dispatch, state }, surveyAnswers) {
      try {
        const response = await submitSurvey(surveyAnswers);
        if (response.success) {
          state.surveySubmitted = true;
        } else {
          dispatch('notifications/addToastError', {
            text: response.message,
          }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not submit survey',
          error,
        }, { root: true });
      }
    },
  },
};
