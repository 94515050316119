import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchMatchesRequest() {
  const response = await axios.get('/api/v1/custom_match_request');
  if (response.data === '') {
    return {};
  }
  return toCamelCase(response.data);
}
async function createMatchesRequest(data) {
  const newData = { ...toSnakeCase(data) };
  const response = await axios.post('/api/v1/custom_match_request', newData);
  return toCamelCase(response.data);
}

async function updateMatchesRequest(data) {
  const newData = { ...toSnakeCase(data) };
  const response = await axios.patch('/api/v1/custom_match_request', newData);
  return toCamelCase(response.data);
}

export {
  fetchMatchesRequest,
  updateMatchesRequest,
  createMatchesRequest,
};
