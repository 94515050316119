function getFilterString(prefix, value) {
  return `${prefix + value},`;
}

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$cloudinarize = (imgSrc, cloudinaryFilters) => {
      if (!imgSrc) {
        return undefined;
      }

      let imageURL = imgSrc;

      // forcing https for cloudinary links
      if (imgSrc && imgSrc.startsWith('http://res.cloudinary.com/')) {
        imageURL = imgSrc.replace('http', 'https');
      }

      if ((!imageURL.includes('/image/upload') && !imageURL.includes('avatars'))) {
        return imageURL;
      }

      const defaultCloudinaryFilters = {
        crop: 'lfill',
        qualityAuto: 'good',
        format: 'auto',
        dpr: '2.0',
        gravity: 'auto:faces',
      };

      const allFilters = {
        ...defaultCloudinaryFilters,
        ...(cloudinaryFilters || {}),
      };

      const filtersAsString = Object.entries(allFilters).reduce((acc, [key, value]) => {
        switch (key) {
          case 'height':
            return acc + getFilterString('h_', value);
          case 'width':
            return acc + getFilterString('w_', value);
          case 'quality':
            return acc + getFilterString('q_', value);
          case 'qualityAuto':
            return acc + getFilterString('q_auto:', value);
          case 'format':
            return acc + getFilterString('f_', value);
          case 'crop':
            return acc + getFilterString('c_', value);
          case 'dpr':
            return acc + getFilterString('dpr_', value);
          case 'gravity':
            return acc + getFilterString('g_', value);
          case 'round':
            return acc + getFilterString('r_', value);
          default:
            return acc;
        }
      }, '');

      if (imageURL.includes('/image/upload')) {
        const cutIndex = imageURL.indexOf('/image/upload') + 14;

        return `${imageURL.slice(0, cutIndex)}${filtersAsString}/${imageURL.slice(cutIndex)}`;
      }

      return `https://res.cloudinary.com/kinside/image/upload/${filtersAsString}/v1/${imageURL}`;
    };
  },
};
