import { axios, toCamelCase } from '@utils';

const BASE_PATH = '/child_care_facilities/';

let fetchFacilityPromise;
async function fetchFacility(friendlyId) {
  if (fetchFacilityPromise) {
    return fetchFacilityPromise;
  }

  const promise = axios.get(
    `${BASE_PATH}${friendlyId}.json`,
  )
    .then(({ data }) => {
      fetchFacilityPromise = null;
      return toCamelCase(data);
    })
    .catch(() => {
      fetchFacilityPromise = null;
    });
  fetchFacilityPromise = promise;

  return promise;
}

async function fetchFacilityTourMode(facilityId) {
  const { data } = await axios.get(
    `${BASE_PATH}${facilityId}/tour_mode.json`,
  );
  return data.tour_mode;
}

async function fetchFacilityTourSlots(facilityId, page) {
  const { data } = await axios.get('/tour_slots.json', {
    params: {
      facility_id: facilityId,
      page,
    },
  });
  return toCamelCase(data);
}

async function reImpersonateParent(parentId) {
  const { data } = await axios.get(`/admin/impersonate/${parentId}.json`);

  return data.success;
}

async function requestMoreMatches() {
  await axios.post('/api/v1/search_requests.json');
}

async function sendWaitlistSpotRequest(params) {
  await axios.post('/api/v1/legup/waitlist_spots', { waitlist_spot: params });
}

let getWaitlistSpotPromise;
async function getWaitlistSpots() {
  if (getWaitlistSpotPromise) {
    return getWaitlistSpotPromise;
  }

  const promise = axios.get('/api/v1/legup/waitlist_spots.json', { get_centers: false })
    .then(({ data }) => {
      getWaitlistSpotPromise = null;
      return data;
    })
    .catch(() => {
      getWaitlistSpotPromise = null;
    });
  getWaitlistSpotPromise = promise;

  return promise;
}

let getWaitlistSpotWithCentersPromise;
async function getWaitlistSpotsWithCenters() {
  if (getWaitlistSpotWithCentersPromise) {
    return getWaitlistSpotWithCentersPromise;
  }

  const promise = axios.get('/api/v1/legup/waitlist_spots.json', { get_centers: true })
    .then(({ data }) => {
      getWaitlistSpotWithCentersPromise = null;
      return data || [];
    })
    .catch(() => {
      getWaitlistSpotWithCentersPromise = null;
    });
  getWaitlistSpotWithCentersPromise = promise;

  return promise;
}

export {
  fetchFacility,
  fetchFacilityTourMode,
  fetchFacilityTourSlots,
  reImpersonateParent,
  requestMoreMatches,
  sendWaitlistSpotRequest,
  getWaitlistSpots,
  getWaitlistSpotsWithCenters,
};
