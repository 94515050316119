<template>
  <div
    class="facility-photo"
    :style="cardImageStyle"
  >
    <span
      v-if="googleAttribution"
      class="facility-photo__attribution"
      v-html="'Photo by ' + googleAttribution"
    ></span>
  </div>
</template>

<script>
import { facilityPlaceholder } from '@images';

export default {
  props: {
    photoUrl: String,
    googleAttribution: String,
    facilityId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cardImageStyle() {
      const cloudinaryFilters = {
        width: '240',
        height: '200',
      };

      if (this.photoUrl && this.googleAttribution) {
        return { backgroundImage: `url(${this.photoUrl})` };
      }
      if (this.photoUrl) {
        return { backgroundImage: `url(${this.$cloudinarize(this.photoUrl, cloudinaryFilters)})` };
      }
      const id = this.facilityId % facilityPlaceholder.length;
      return { backgroundImage: `url(${this.$cloudinarize(facilityPlaceholder[id], cloudinaryFilters)})` };
    },
  },
};
</script>

<style lang="scss" scoped>
.facility-photo {
  position: relative;
  background-color: var(--gray-5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__attribution {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: calc(var(--grid-unit) / 2);
    font-size: 10px;
    color: var(--gray-0);

    &::v-deep a {
      color: var(--gray-0);
      text-decoration: underline;
    }
  }
}

</style>
