import {
  fetchRecurringPaymentConfigs,
  fetchRecurringPaymentConfig,
  updateRecurringPaymentsConfig,
  cancelRecurringPaymentsConfig,
} from './recurringPayments.service';

export default {
  namespaced: true,

  state: {
    list: [],
    scheduledPaymentRequests: {},
  },

  getters: {
    getScheduledPaymentRequests: (state) => (paymentId) =>
      state.scheduledPaymentRequests[paymentId],

    getRecurringPayment: (state) => (paymentId) =>
      state.list.find((payment) => payment.id === paymentId),
  },

  mutations: {
    setPayments: (state, payments) => {
      state.list = payments;
    },

    setScheduledPaymentRequests: (state, { paymentId, requests }) => {
      state.scheduledPaymentRequests = {
        ...state.scheduledPaymentRequests,
        [paymentId]: requests,
      };
    },
  },

  actions: {
    async updateRecurringPaymentsConfig({ dispatch }, { paymentId, data }) {
      try {
        await updateRecurringPaymentsConfig(paymentId, data);
        dispatch('fetchRecurringPaymentConfigsConfig');
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not update payment config', error },
          { root: true },
        );
        throw new Error();
      }
    },

    async cancelRecurringPaymentsConfig({ dispatch }, paymentId) {
      try {
        await cancelRecurringPaymentsConfig(paymentId);
        dispatch('fetchRecurringPaymentConfigsConfig');
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not cancel payment config', error },
          { root: true },
        );
        throw new Error();
      }
    },

    async fetchRecurringPaymentConfigsConfig({ commit, dispatch }) {
      try {
        const payments = await fetchRecurringPaymentConfigs();
        commit('setPayments', payments);
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch payment configs', error },
          { root: true },
        );
        throw new Error();
      }
    },

    async fetchRecurringPaymentConfig({ commit, dispatch }, paymentId) {
      try {
        const payments = await fetchRecurringPaymentConfig(paymentId);
        commit('setScheduledPaymentRequests', {
          paymentId,
          requests: payments.paymentRequests,
        });
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch payment configs', error },
          { root: true },
        );
        throw new Error();
      }
    },
  },
};
