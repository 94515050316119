import {
  removeAvatar,
  updateNotificationSettings,
  updateSettings,
  updateName,
  notificationSettings,
  getUser,
  updateOnboardingForParent,
  sendEnrollment,
  fetchParentEnrollments,
  requestMoreInformation,
  fetchInfoRequestHistory,
  fetchSubsidies,
  updateSubsidies,
  addUser,
  updateNameEmail,
} from './parent-user.service';

export default {
  namespaced: true,

  state: {
    current: {
      id: undefined,
      company: undefined,
      type: undefined,
      email: undefined,
      parentSubsidiesEnabled: undefined,
      name: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      avatar: undefined,
      emailSettings: 'all',
      confirmedAt: undefined,
      isImpersonated: false,
      completeCare: false,
      parentPaymentsEnabled: false,
      onboardingCompleted: false,
      naviaParent: false,
      legupParentId: undefined,
      previewProfile: false,
      isPaymentCollectionUser: false,
      conciergeSubscriptionEnabled: false,
      isPayingCompany: false,
      auth_etc: {},
    },

    notificationSettings,

    enrollments: [],
    requestInfoHistory: undefined,

    enabledSubsidies: [],
    availableSubsidies: [],

    upcomingTour: {},
    upcomingTourAgeGroupId: '',

    dependentsWithSpots: [],
  },

  getters: {
    firstName: (state) => state.current.firstName,
    currentParent: (state) => state.current,
    isPaymentCollectionOnboarding: (state) => {
      const currentUser = state.current;
      return !currentUser.parentPaymentOnboarding && currentUser.paymentCollection;
    },
    isPaymentCollection: (state) => {
      const currentUser = state.current;
      return currentUser.parentPaymentOnboarding && currentUser.paymentCollection;
    },
    upcomingTour: (state) => state.upcomingTour,
    upcomingTourAgeGroupId: (state) => state.upcomingTourAgeGroupId,
    dependentsWithSpots: (state) => state.dependentsWithSpots,
  },

  mutations: {
    current: (state, payload) => {
      state.current = { ...payload };
    },
    phoneNumber: (state, phoneNumber) => {
      state.current.phoneNumber = phoneNumber;
    },
    parentSubsidiesEnabled(state, subsidies) {
      state.current.parentSubsidiesEnabled = subsidies;
    },
    clearAvatar(state) {
      state.current = { ...state.current, avatar: undefined };
    },
    updateNotificationSettings(state, settings) {
      state.current = { ...state.current, emailSettings: settings };
    },
    addEnrollments(state, enrollments) {
      state.enrollments = [...enrollments, ...state.enrollments];
    },
    addRequestInfo(state, info) {
      state.requestInfoHistory = info;
    },
    setEnabledSubsidies(state, subsidies) {
      state.enabledSubsidies = subsidies;
    },
    setAvailableSubsidies(state, subsidies) {
      state.availableSubsidies = subsidies;
    },
    upcomingTour(state, payload) {
      state.upcomingTour = payload;
    },
    upcomingTourAgeGroupId(state, payload) {
      state.upcomingTourAgeGroupId = payload;
    },
    dependentsWithSpots(state, payload) {
      state.dependentsWithSpots = payload;
    },
  },

  actions: {
    setCurrentUser({ commit }, user) {
      commit('current', user);
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$setupUserAccess(user);
    },

    async fetchCurrent({ dispatch, state }) {
      try {
        const user = await getUser();
        dispatch('setCurrentUser', user);
        dispatch('track/identifyUser', {
          id: state.current.id,
          traits: state.current,
        }, { root: true });

        // eslint-disable-next-line no-underscore-dangle
        if (this._vm.$userAccess({ can: 'doPayments' })) {
          dispatch('payments/fetchPaymentMethods', undefined, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load user', error }, { root: true });
      }
    },

    async updateSettings({ dispatch }, { phoneNumber, contactPreferenceSms }) {
      try {
        const { user } = await updateSettings({ phoneNumber, contactPreferenceSms });
        dispatch('setCurrentUser', user);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update user settings', error }, { root: true });
      }
    },

    async updateName({ dispatch }, { firstName, lastName }) {
      try {
        const { user } = await updateName({ firstName, lastName });
        dispatch('setCurrentUser', user);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update first and last name', error }, { root: true });
      }
    },

    async updateNameEmail({ dispatch }, {
      email, firstName, lastName, id,
    }) {
      try {
        const { user } = await updateNameEmail({
          email, firstName, lastName, id,
        });
        dispatch('setCurrentUser', user);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update email', error }, { root: true });
      }
    },

    async fetchSubsidies({ commit, dispatch }) {
      try {
        const response = await fetchSubsidies();
        if (response?.success) {
          commit('setEnabledSubsidies', response.enabledSubsidies);
          commit('setAvailableSubsidies', response.availableSubsidies);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch parent subsidies', error }, { root: true });
      }
    },

    async updateSubsidies({ commit, dispatch }, params) {
      try {
        const response = await updateSubsidies(params);
        if (response?.success) {
          commit('setEnabledSubsidies', response.user.parentSubsidiesEnabled);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update parent subsidies', error }, { root: true });
      }
    },

    async updateOnboarding({ dispatch, state }, userObj) {
      try {
        const user = await updateOnboardingForParent(userObj);
        dispatch('setCurrentUser', user);
        dispatch('track/identifyUser', {
          id: state.current.id,
          traits: state.current,
        }, { root: true });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update user', error }, { root: true });
      }
    },

    async removeAvatar({ commit, dispatch }) {
      try {
        await removeAvatar();
        commit('clearAvatar');
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not remove avatar', error }, { root: true });
        throw new Error();
      }
    },
    async updateNotificationSettings({ commit, dispatch }, settings) {
      try {
        await updateNotificationSettings(settings);
        commit('updateNotificationSettings', settings);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update notification settings', error }, { root: true });
        throw new Error();
      }
    },

    async sendEnrollment({ dispatch, commit }, data) {
      try {
        const newEnrollment = await sendEnrollment(data);
        commit('addEnrollments', [newEnrollment]);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not save an enrollment. Try again', error }, { root: true });
      }
    },

    async fetchEnrollments({ dispatch, commit }) {
      try {
        const enrollments = await fetchParentEnrollments();
        commit('addEnrollments', enrollments);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch enrollments', error }, { root: true });
      }
    },

    async requestInformation({ dispatch, commit }, data) {
      try {
        await requestMoreInformation(data);
        commit('addRequestInfo', new Date().toISOString());
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Failed to trigger an information request', error },
          { root: true },
        );
      }
    },

    async loadInfoRequestHistory({ dispatch, commit }, facilityId) {
      try {
        const infoRequestHistory = await fetchInfoRequestHistory(facilityId);
        commit('addRequestInfo', infoRequestHistory);
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Could not fetch the information request history data', error },
          { root: true },
        );
      }
    },

    async addUser({ dispatch }, userData) {
      let response;
      try {
        response = await addUser(userData);
        if (response && !response.success) {
          dispatch('notifications/addToastError', { text: (response.message || 'Could not add user') }, { root: true });
        } else {
          dispatch('setCurrentUser', response.user);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not add user', error }, { root: true });
      }
      return response;
    },
  },

};
