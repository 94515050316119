<template>
  <transition name="toast">
    <div class="toast" :class="{'toast--error': isError}">
      {{text}}
      <div v-if="!!error" class="font-body-small toast__dev" >
        <p v-if="error.fileName">{{error.fileName}} : {{error.lineNumber}}</p>
        <p>
          {{
            error.response
            ? `${error.response.status}: ${JSON.stringify(error.response.data)}`
            : error.message
          }}
        </p>
      </div>
      <close-button
        @click.native="dismiss"
        class="toast__close-button"
        :color="isError ? 'green' : 'white'"
      />
    </div>
  </transition>
</template>

<script>
import CloseButton from '@components/buttons/close-button.vue';

export default {
  components: {
    CloseButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    error: {
      type: [Error, String, Boolean],
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeoutId: undefined,
    };
  },
  mounted() {
    this.timeoutId = setTimeout(this.dismiss, 5000);
    if (this.error) {
      // TODO: NL: 2022/11/09: Do something with this error.
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    dismiss() {
      this.$store.dispatch('notifications/removeToastNotification', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: relative;
  display: inline-block;
  padding: calc(var(--grid-unit) * 1.5);
  padding-right: calc(var(--grid-unit) * 6);
  margin-bottom: calc(var(--grid-unit) / 2);
  color: var(--gray-0);
  background: var(--viridian-50);
  border-radius: var(--radius-big);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  &--error {
    color: var(--rust-80);
    background: var(--rust-10);
  }

  &__dev {
    margin-top: var(--grid-unit);
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.toast-enter-active,
.toast-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.toast-enter-to,
.toast-leave {
  opacity: 1;
  transform: translateY(0);
}

.toast-enter,
.toast-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
