<template>
  <div class="illustration" :class="name">
    <figure class="illustration__image" :style="{'background-image': illustrationUrl}"></figure>
  </div>
</template>

<script>
import { illustrations } from '@images';

const filters = {
  employee: { width: 181 },
  boyGirl: { width: 213 },
  chalkboard: { width: 192 },
  map: { width: 198 },
  momDaughter: { width: 153 },
  momDaughter2: { width: 111 },
  plants: { width: 196 },
  sun: { width: 70 },
  trees: { width: 68 },
  toys: { width: 145 },
  block: { width: 185 },
  warning: { width: 160 },
  moon: { width: 182 },
  search: { width: 150 },
  child1: { width: 152 },
  child2: { width: 164 },
  girl: { width: 200 },
};

export default {
  name: 'illustration',

  props: {
    name: {
      type: String,
      default: 'sun',
    },
  },
  computed: {
    illustrationUrl() {
      return `url(${this.$cloudinarize(illustrations[this.name], filters[this.name])})`;
    },
  },

};
</script>

<style lang="scss" scoped>

  .illustration {
    display: block;

    &__image {
      display: block;
      height: 0;
      margin: 0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .employee {
    max-width: 181px;

    .illustration__image {
      padding-top: 90%;
    }
  }

  .boyGirl {
    max-width: 213px;

    .illustration__image {
      padding-top: 96%;
    }
  }

  .chalkboard {
    max-width: 192px;

    .illustration__image {
      padding-top: 56%;
    }
  }

  .map {
    max-width: 198px;

    .illustration__image {
      padding-top: 68%;
    }
  }

  .momDaughter {
    max-width: 153px;

    .illustration__image {
      padding-top: 78%;
    }
  }

  .momDaughter2 {
    max-width: 111px;

    .illustration__image {
      padding-top: 156%;
    }
  }

  .plants {
    max-width: 196px;

    .illustration__image {
      padding-top: 73.4%;
    }
  }

  .sun {
    max-width: 70px;

    .illustration__image {
      padding-top: 94%;
    }
  }

  .trees {
    width: 68px;
    max-width: 68px;

    .illustration__image {
      padding-top: 94%;
    }
  }

  .toys {
    max-width: 145px;

    .illustration__image {
      padding-top: 98%;
    }
  }

  .block {
    max-width: 185px;

    .illustration__image {
      padding-top: 64%;
    }
  }

  .moon {
    max-width: 182px;

    .illustration__image {
      padding-top: 69%;
    }
  }

  .child1 {
    .illustration__image {
      padding-top: 30%;
    }
  }

  .child2 {
    .illustration__image {
      padding-top: 30%;
    }
  }

  .search {
    .illustration__image {
      padding-top: 30%;
    }
  }

  .mailbox {
    .illustration__image {
      padding-top: 90%;
    }
  }

  .mailbox.register {
    .illustration__image{
      padding-top: 30%;
    }
  }

  .girl {
    .illustration__image {
      padding-top: 30%;
    }
  }

  .warning {
    max-width: 160px;
    margin: auto;

    .illustration__image {
      padding-top: 64%;
    }
  }

  .boyGirl2 {
    .illustration__image {
      padding-top: 40%;
    }
  }

  .block5 {
    max-width: 96px;

    .illustration__image {
      padding-top: 85%;
    }
  }
</style>
